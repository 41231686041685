import _ from "lodash";
import { api } from "../../server/request";
import { handleError, handleResponse } from "../../actions/responseUtil";

const ACTION_TYPES = {
    SET_LOADING: "starlinkDashboard/setLoading",
    GET_SERVICE_LINES: "starlinkDashboard/getServiceLines",
    GET_WARNINGS: "starlinkDashboard/getWarnings",
    GET_STARLINK_LAST_LOCATION: "starlinkDashboard/getStarlinkLastLocation",
    GET_STARLINK_MAP_TELEMETRY: "starlinkDashboard/getStarlinkMapTelemetry",
}

const initialState = {
    serviceLines: [],
    totalServiceLines: 0,
    totalUsageGB: 0,
    totalOverageGB: 0,
    totalOverage: 0,
    totalUserTerminals: 0,
    totalOnlineTerminals: 0,
    totalActiveAlerts: 0,
    totalAlertingTerminals: 0,
    warnings: [],
    gettingServiceLines: false,
    gettingWarnings: false,
    starlinkLastLocations:[],
    starlinkMapTelemetry:[]
}
export function starlinkDashboardReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_SERVICE_LINES: {
            const data = action.payload.data[0];
            return {
                ...state,
                serviceLines: action.payload.data.length > 0 ? JSON.parse(data?.result) || [] : [],
                totalServiceLines: data?.totalCount ? data?.totalCount : 0,
                totalUsageGB: data?.totalUsageGB ? data?.totalUsageGB : 0,
                totalOverageGB: data?.totalOverageGB ? data?.totalOverageGB : 0,
                totalOverage: data?.totalOverage ? data?.totalOverage : 0,
                totalUserTerminals: data?.totalUserTerminals ? data?.totalUserTerminals : 0,
                totalOnlineTerminals: data?.totalOnlineTerminals ? data?.totalOnlineTerminals : 0,
                totalActiveAlerts: data?.totalActiveAlerts ? data?.totalActiveAlerts : 0,
                totalAlertingTerminals: data?.totalAlertingTerminals ? data?.totalAlertingTerminals : 0,
            }
        }
        case ACTION_TYPES.GET_WARNINGS: {
            const warnings = action.payload?.data?.rows?.map(row => row[0])?.filter(warning => warning);
            return {
                ...state,
                warnings: warnings || [],
            }
        }
        case ACTION_TYPES.GET_STARLINK_LAST_LOCATION: {
            const locations = action.payload?.data?.rows;
            return {
                ...state,
                starlinkLastLocations: locations || [],
            }
        }
        case ACTION_TYPES.GET_STARLINK_MAP_TELEMETRY: {
            const data = action.payload?.data;
            return {
                ...state,
                starlinkMapTelemetry: data || [],
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state
    }
}

export interface getServiceLinesParameters {
    dpIds: string[];
    startDate: string;
    endDate: string;
    searchText: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
    source:string;
    usageSource:string;
    serviceLines: string[];
    accounts: string[];
    offlineServiceLines:boolean;
    noTerminals:boolean;
    offlineTerminals:boolean;
    alertingTerminals:boolean;
}

export const getServiceLines = (parameters: getServiceLinesParameters) => (dispatch) => {
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters: any = {
        // dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        accounts: parameters?.accounts ? parameters?.accounts?.map(acc => `'${acc}'`).join(',') : "",
        serviceLines: parameters?.serviceLines?.map(sl => `'${sl}'`).join(','),
        startDate: parameters?.startDate,
        endDate: parameters?.endDate,
        searchText: parameters?.searchText,
        sortBy: parameters?.sortBy,
        sortOrder: parameters?.sortOrder,
        limit: parameters?.limit,
        source:parameters?.source,
        usageSource: parameters?.usageSource,
        offset: (parameters?.page - 1) * parameters?.limit,
        offlineServiceLines: parameters?.offlineServiceLines ? parameters?.offlineServiceLines : false,
        noTerminals: parameters?.noTerminals ? parameters?.noTerminals : false,
        offlineTerminals: parameters?.offlineTerminals ? parameters?.offlineTerminals : false,
        alertingTerminals: parameters?.alertingTerminals ? parameters?.alertingTerminals : false,
    };
    const data = {
        query: `GET_SERVICE_LINES_SPORT_V2`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINES)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        });
}

export interface getStarlinkCloudWarningsParameters {
    dpIds: string[];
}

export const getStarlinkCloudWarnings = (parameters: getStarlinkCloudWarningsParameters) => (dispatch) => {
    if (_.isEmpty(parameters?.dpIds)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: true } });
    let _parameters: any = {
        dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
    };
    const data = {
        query: `GET_STARLINK_CLOUD_WARNINGS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_WARNINGS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: false } });
        });
}

export const getStarlinkLastLocation = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: true } });
    let _parameters = {
          dp_id: parameters?.dpId,
          site_ids: "",
          "service_line_numbers": parameters?.serviceLines?.map(sl => `${sl}`).join(','),
          "is_edge": "false",
          "is_starlink": "true",
          "is_edge_starlink":"false",
          source: parameters?.source,
          start_date: parameters?.startDate,
          end_date: parameters?.endDate,
          usageSource: parameters?.usageSource,
          offline_service_lines: parameters?.offlineServiceLines ? parameters?.offlineServiceLines : false,
          no_terminals:parameters?.noTerminals ? parameters?.noTerminals : false,
          offline_terminals: parameters?.offlineTerminals ? parameters?.offlineTerminals : false,
          alerting_terminals: parameters?.alertingTerminals ? parameters?.alertingTerminals : false,
        
      }
    const data = {
        query: `GET_STARLINK_MAPS_SL_LIST`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_LAST_LOCATION)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: false } });
        });
}

export const getStarlinkMapTelemetry = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: true } });
    let _parameters = {
        userTerminalId: parameters?.userTerminalId, 
      }
    const data = {
        query: `GET_STARLINK_MAPS_UT_TELEMETRY`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_MAP_TELEMETRY)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingWarnings: false } });
        });
}
export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_SERVICE_LINES, payload: { data: { rows: [[[], 0]] } } })
}