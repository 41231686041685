// import { useState } from "react";
import { Fragment, useEffect, useRef, useState, CSSProperties } from "react";
import L from "leaflet";
import { MapContainer, Marker, Popup, useMapEvents, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { getServiceLines, clearComponentData, getStarlinkLastLocation } from "../StarlinkDashboard/slice";
import CircleIcon from '@mui/icons-material/Circle';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SortArrows, { SortOrder } from "../SortArrows";
import { getDecodeURI, getEncodedURI } from '../../utils/util';
import moment, { Moment } from "moment-timezone";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { TableContainer, TableHead, Table, TableRow, Paper, TableCell, TableBody, Tooltip, IconButton, Grid, Box, Button, Menu, LinearProgress, Divider, FormGroup, FormControlLabel, Checkbox, Chip, Typography } from "@mui/material";
import { STARLINK_DATAUSAGE_CATEGORIES, STARLINK_DATAUSAGE_CATEGORIES_DATA } from "../../UserScreen/Starlink/types";
import { Pagination } from "../Pagination";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import StarlinkMap from "./StarlinkMap";
import close from "../../asset/image/close.svg";
import ArrowCircleDownIcon from '@mui/icons-material/ExpandMore';
import ArrowCircleUpIcon from '@mui/icons-material/ExpandLess';
import Download from '../../asset/image/DownloadUsage.svg';
import _ from "lodash";
import TuneIcon from '@mui/icons-material/Tune';
import { YYYYMMDD_HHmmss } from "../../utils/constants";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";

const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

export type DashboardSortBy = 'nickname' | 'accountDpName' | 'dpName' | 'usageGB' | 'kitSerialNumber' | 'activeAlerts' | 'k4SiteName' | 'online';

const alertMap = {
  50: "ethernet_slow_10mbps",
  51: "ethernet_slow_100mbps",
  52: "power_supply_thermal_throttling",
  53: "actuator_motor_stuck",
  54: "mast_not_vertical",
  55: "disabled_no_active_account",
  56: "disabled_too_far_from_service_address",
  57: "disabled_no_service_in_ocean",
  58: "disabled_invalid_country",
  59: "moving_while_not_mobile",
  60: "disabled_moving_too_fast",
  61: "power_disconnect_detected",
  62: "pop_change",
  79: "software_update_reboot_pending",
  63: "unable_to_align",
  64: "high_sky_obstruction",
  65: "high_sky_obstruction_mobile",
  66: "high_time_obstruction",
  67: "high_time_obstruction_mobile",
  80: "thermal_shutdown",
  81: "thermal_throttling",
  82: "software_update_reboot_pending",
  "over_threshold": "over_quota_threshold",
  "over_limit": "over_max_quota",
  "offline_for_15m": "Offline for more than 15 minutes",
  "offline_for_1h": "Offline for more than 1 hour",
  "offline_for_6h": "Offline for more than 6 hours",
}

const CsvHeaders = ['Service Line', 'Organization', 'Sub Organization','Subscription', 'usagePriority (GB)', 'usageOptInPriority (GB)', 'usageStandard (GB)', 'usageNonBillable (GB)', 'User Terminal', 'Starlink/Edge Alerts', 'Site'];

function Card(props) {
  const { title, leftTitle, leftContent, rightTitle, rightContent, progress } = props;
  return (
    <Paper classes={{ root: 'starlink--base--flex--column starlink--base--flex--justify--space_between starlink--base--padding_05x' }}>
      <Typography component="div" className="starlink--dashboard--card_title">
        {title}
      </Typography>
      <Grid classes={{ root: 'starlink--base--flex--row starlink--base--flex--justify--space_around starlink--base--mtb_07x' }}>
        <Grid>
          <Typography component="div" classes={{ root: 'starlink--base--font_08x starlink--base--text_center' }}>
            {leftTitle}
          </Typography>
          <Typography component="div" classes={{ root: 'starlink--base--font_1x starlink--base--text_center' }}>
            {leftContent}
          </Typography>
        </Grid>
        <Grid>
          <Typography component="div" classes={{ root: 'starlink--base--font_08x starlink--base--text_center' }}>
            {rightTitle}
          </Typography>
          <Typography component="div" classes={{ root: 'starlink--base--font_1x starlink--base--text_center' }}>
            {rightContent}
          </Typography>
        </Grid>

      </Grid>
      <Box className="starlink--dashboard--card_progress">
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </Paper>
  )
}

const StarlinkMaps = (props) => {
  const { location, history, authReducer, dpIds, newSummaryStartDate, newSummaryEndDate, totalServiceLines, newSummaryServiceLine, selectedServiceAccount, clearComponentData, serviceLines, getServiceLines, getStarlinkLastLocation, starlinkLastLocations, totalUsageGB, totalOverageGB, totalOverage, totalUserTerminals, totalOnlineTerminals, totalActiveAlerts, totalAlertingTerminals, downloadAsCSV } = props
  const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
  const [starlinkLocations, setStarlinkLocation] = useState<any>(null)
  const [selectedServiceLine, setSelectedServiceLine] = useState<any>(null)
  const [filterPopup, setFilterPopup] = useState(false);
  const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();
  const dispatch = useDispatch();
  const defaultFilters = [
    { name: "Offline", label: "serviceLineStatus", selected: false },
    { name: "No Terminals", label: "noTerminals", selected: false },
    { name: "Offline Terminals", label: "offlineTerminals", selected: false },
    { name: "Alerting Terminals", label: "alertingTerminals", selected: false },
  ];

  const [filters, setFilters] = useState([...defaultFilters]);
  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const [openCard, setOpenCards] = useState(true)
  const [tableParams, setTableParams] = useState<{
    page: number;
    size: number;
    sortBy: DashboardSortBy;
    sortOrder: SortOrder;
    search: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
  }>({
    page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
    size: queryParams.get('size') ? Number(queryParams.get('size')) : 100,
    sortBy: queryParams.get('sortBy') as DashboardSortBy || 'usageGB',
    sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
    search: queryParams.get('search') || '',
    startDate: newSummaryStartDate,
    endDate: newSummaryEndDate
  });

  const updateQueryParams = () => {
    queryParams.set('page', tableParams.page.toString())
    queryParams.set('size', tableParams.size.toString())
    queryParams.set('sortBy', tableParams.sortBy)
    queryParams.set('sortOrder', tableParams.sortOrder)
    queryParams.set('search', tableParams.search)
    history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
  }

  const getSource = () => {
    const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
    const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

    const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();

    if (hoursDifference < 6) {
      return '_agg_1h';
    } else if (hoursDifference >= 6 && hoursDifference < 24) {
      return '_agg_6h';
    } else if (hoursDifference >= 24 && hoursDifference < 168) {
      return '_agg_1day';
    } else if (hoursDifference >= 168) {
      return '_agg_1week';
    } else {
      return ''
    }
  }

  const getUsageSource = () => {
    const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
    const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

    const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
    if (hoursDifference <= 24) {
      return 'aggregation_table';
    } else if (hoursDifference > 24) {
      return 'direct_source';
    } else {
      return ''
    }
  }

  useEffect(() => {
    updateQueryParams();
    setSelectedServiceLine(null)

    let offlineServiceLines = false, noTerminals = false, offlineTerminals = false, alertingTerminals = false;

    if (selectedFilters) {
      selectedFilters?.forEach((filter) => {
        switch (filter.label) {
          case "serviceLineStatus":
            offlineServiceLines = true;
            break;
          case "noTerminals":
            noTerminals = true;
            break;
          case "offlineTerminals":
            offlineTerminals = true;
            break;
          case "alertingTerminals":
            alertingTerminals = true;
            break;
          default:
            break;
        }
      });
    }
    let _parameters = {
      accounts: selectedServiceAccount,
      dpIds,
      startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      searchText: tableParams.search,
      sortBy: tableParams.sortBy,
      sortOrder: tableParams.sortOrder,
      page: tableParams.page,
      limit: tableParams.size,
      source: "_agg_1day",
      serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl != '') : [],
      usageSource: getUsageSource(),
      offlineServiceLines: offlineServiceLines,
      noTerminals: noTerminals,
      offlineTerminals: offlineTerminals,
      alertingTerminals: alertingTerminals
    }
    clearComponentData()

    getServiceLines(_parameters);

   //API to get location data
    getStarlinkLastLocation({
      dpId: authReducer?.selectedOu?.id,
      serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl != '') : [],
      source: "_agg_1day",
      offlineServiceLines: offlineServiceLines,
      noTerminals: noTerminals,
      offlineTerminals: offlineTerminals,
      alertingTerminals: alertingTerminals,
      startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    })

  }, [tableParams, newSummaryServiceLine, selectedFilters]);

  const handleChangePage = (event: any, newPage: number) => {
    setTableParams({ ...tableParams, page: newPage });
    setSelectedServiceLine(null)
  };

  const handleChangeSize = (event: any) => {
    setTableParams({ ...tableParams, size: event.target.value, page: 1 });
  };

  const handleChangeSorting = (sortBy: DashboardSortBy, sortOrder: SortOrder) => {
    setTableParams({
      ...tableParams,
      sortBy: sortBy,
      sortOrder: sortOrder,
      page: 1
    });
  }

  useEffect(() => {
    if (!_.isEmpty(starlinkLastLocations)) {
      let starlinkLocations = starlinkLastLocations?.map((location: any) => {
        const [site_id, service_line_number, ts, lat, long, site_name, service_line_name, source, status, edge_kpi, starlink_kpi, is_starlink_only, is_edge_only, user_terminals] = location

        return { site_id, service_line_number, ts, lat, long, site_name, service_line_name, source, status, edge_kpi, starlink_kpi, is_starlink_only, is_edge_only, user_terminals }
      })
      setStarlinkLocation(starlinkLocations)
    }else{
      setStarlinkLocation(null)
    }
  }, [starlinkLastLocations])

  // useEffect(() => {
  //   if (serviceLines && serviceLines.length > 0) {
  //     setSelectedServiceLine(serviceLines[0])
  //   }
  // }, [serviceLines])

  const handleInternetStatusFilterChange = (index, value) => {
    const updatedFilters = [...filters];
    updatedFilters[index].selected = value;
    setFilters(updatedFilters);
  };

  const handleApplyFilters = () => {
    const appliedFilters = filters.filter((filter) => filter.selected);
    setSelectedFilters(appliedFilters);
    setFilterPopup(false);
  };

  const clearFilter = (filterLabel) => {
    const updatedFilters = filters.map((filter) =>
      filter.label === filterLabel ? { ...filter, selected: false } : filter
    );
    setFilters(updatedFilters);
    setSelectedFilters(updatedFilters.filter((filter) => filter.selected));
  };

  const clearFilters = () => {
    setFilters([...defaultFilters]); // Reset filters to default
    setSelectedFilters([]); // Clear selected filters
  };

  const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElRef(e.currentTarget);
    setFilterPopup(true);
  };

  const handleDownloadReport = () => {
    let offlineServiceLines = false, noTerminals = false, offlineTerminals = false, alertingTerminals = false;

    if (selectedFilters) {
      selectedFilters?.forEach((filter) => {
        switch (filter.label) {
          case "serviceLineStatus":
            offlineServiceLines = true;
            break;
          case "noTerminals":
            noTerminals = true;
            break;
          case "offlineTerminals":
            offlineTerminals = true;
            break;
          case "alertingTerminals":
            alertingTerminals = true;
            break;
          default:
            break;
        }
      });
    }

    const params: CsvParameters = {
        type: 'QUERY_CH',
        queryName: 'GET_SERVICE_LINES_SPORT_V2',
        id: "starlink-dashboard",
        payload: {
            dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
            startDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
            endDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            source: getSource(),
            usageSource:getUsageSource(),
            limit: totalServiceLines,
            offset: 0,
            serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl=>sl != '').map(sl => `'${sl}'`).join(','),
            offlineServiceLines: offlineServiceLines,
            noTerminals: noTerminals,
            offlineTerminals: offlineTerminals,
            alertingTerminals: alertingTerminals
        }
    }
    downloadAsCSV(params);
}

const mapToCsvRow = (row: any) => {
  const values: any[] = Object.values(row);
  const userTerminals: any[] = row.userTerminals != '' && JSON.parse(row.userTerminals) || [];
  const usageSplit: any[] = row.usageSplit != '' && JSON.parse(row.usageSplit) || [];
  return [
    values[8],
   values[5], 
   values[3], 
   `${values[22]}, ${values[23]}`.replaceAll(',', ';'),  
   usageSplit[0]?.usagePriorityGB,
    usageSplit[0]?.usageOptInPriorityGB,
     usageSplit[0]?.usageStandardGB,
      usageSplit[0]?.usageNonBillableGB,
   userTerminals.map(value => value.kitSerialNumber).join(';'),
     userTerminals.map(value => {
          if (value.activeAlerts) {
              try {
                  const alerts = JSON.parse(value.activeAlerts);
                  return alerts.length;
              } catch (error) {
                  console.error("Error parsing activeAlerts:", error);
                  return 0; 
              }
          } else {
              return 0; 
          }
      }).join(';'),
      values[6]
      ]
}

useEffect(() => {
  if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
      if (authReducer.csvDownloadId === 'starlink-dashboard') {
          const [_columns, status] = authReducer.csvDataDownloded;
          if (status && status.length > 0) {
              const rows = JSON.parse(status[0]);
              if (rows.length) {

                  DownloadCSV([CsvHeaders, ...rows.map(row => mapToCsvRow(row))], {
                      formatters: {
                          0: 'ESC-COMMA',
                          1: 'ESC-COMMA',
                          2: 'ESC-COMMA',
                          3: 'ESC-COMMA',
                          // 5: dataBucketFormat,
                          // 10: 'ESC-COMMA',
                          // 11: 'ESC-COMMA',
                      }
                  }
                  );
              }
          }
          return () => {
              dispatch(clearCsvDownload())
          }
      }
  }
}, [authReducer.csvDataDownloded]) 

  return (
    <div className="starlinkMapContainer">
      <div className="starlinkMap-filterBtn">
        <Button
          variant="outlined"
          startIcon={<TuneIcon id="filterIcon" />}
          onClick={filterPopClick}
          id="filterButton"
        >
          Filter
        </Button>

        <div className="starlinkMap-selFilter">
          {selectedFilters.map((filter) => (
            <Chip
              key={filter.label}
              label={filter.name}
              onDelete={() => clearFilter(filter.label)}
              className="starlinkMap-chips"
            />
          ))}
        </div>

        {filterPopup ? (
          <Menu
            open={filterPopup}
            className="rc--filter_menu_container_dashboard menuScroll"
            anchorEl={anchorElRef}
          >
            <Grid className="sitesFilterHeaderMargin">
              <div className="filter-header">Filters</div>
              <img
                src={close}
                onClick={() => setFilterPopup(false)}
                alt="Close"
                className="activityReportsFilter-close"
              />
            </Grid>
            <Grid className="filter-divider-width">
              <Divider className="filter-border-color" />
            </Grid>
            <Grid className="usageFilterComponent">
              <div className="Form">
                <Grid className="am-table">
                  <Grid className="dash-filter-pop">
                    <Grid>
                      <Grid className="sitesFiltersPadding">
                        <Grid className="internetr-status-header-paddingBottom">
                          <span className="internet-status-header">Service Lines</span>
                        </Grid>
                        <FormGroup
                          sx={{ padding: 0 }}
                          className="filterComponents"
                        >
                          {filters.map((status, index) => (
                            <FormControlLabel
                              className="sitesInternetStatusOptions starlinkMap-filterPopup"
                              key={`form-filter-${status.label}`}
                              sx={{ padding: 0 }}
                              control={
                                <Checkbox
                                  style={{ padding: "0px 8px 0px 8px" }}
                                  name={status.label}
                                  checked={status.selected}
                                  onChange={(_, value) =>
                                    handleInternetStatusFilterChange(index, value)
                                  }
                                />
                              }
                              label={status.name}
                              
                            />
                          ))}
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid className="apply-filter-divider-width">
              <Divider className="filter-border-color" />
            </Grid>
            <div className="clearFiletrBtnDiv margin-left-filter">
              <Button
                className="clearFiletrBtnUsage padding-right-0"
                onClick={clearFilters}
              >
                Clear Filter
              </Button>
              <Grid className="dash-filter-pop">
                <Button
                  className="usage-applyFilterBtn"
                  onClick={handleApplyFilters}
                >
                  Apply Filter
                </Button>
              </Grid>
            </div>
          </Menu>
        ) : null}

        <div  className="starlinkMap-OpenCard">
          {totalServiceLines > 0 && <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={handleDownloadReport} disabled={false} startIcon={<img className="downloadImg" src={Download} alt="" />}>Download</Button>}
          {openCard ? <ArrowCircleUpIcon className="starlinkMap-icon" onClick={()=>{setOpenCards(!openCard)}} /> : <ArrowCircleDownIcon className="starlinkMap-icon" onClick={()=>{setOpenCards(!openCard)}} />}
        </div>
      </div>

      {openCard &&
        <div >
          <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Card title="Service Lines" leftTitle="Total" leftContent={totalServiceLines} rightTitle="Overage" rightContent={totalOverage} progress={(totalServiceLines - totalOverage) / totalServiceLines * 100} />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Card title="User Terminals" leftTitle="Total" leftContent={totalUserTerminals}
                  rightTitle="Offline" rightContent={totalUserTerminals - totalOnlineTerminals} progress={totalOnlineTerminals / totalUserTerminals * 100} />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Card title="Usage" leftTitle="Total" leftContent={`${totalUsageGB} GB`} rightTitle="Overage (MTD)" rightContent={`${totalOverageGB} GB`} progress={(totalUsageGB - totalOverageGB) / totalUsageGB * 100} />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Card title="Alerts" leftTitle="Total Alerts" leftContent={totalActiveAlerts} rightTitle="Alerting Terminals" rightContent={totalAlertingTerminals} progress={(totalUserTerminals - totalAlertingTerminals) / totalUserTerminals * 100} />
              </Grid>
            </Grid>
          </Grid>
        </div>}

      <div className="containerStyleMap">
        <div className="starlinkMap-Main">
          <Pagination count={totalServiceLines} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />
          <TableContainer
            component={Paper}
            className="starlink--table_container strMap-table"
          >
            <Table stickyHeader size="small" aria-label="starlink--dashboard">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="nickname" onChange={handleChangeSorting}>
                      <span className="font-wt-900">SERVICE LINE</span>
                    </SortArrows>
                  </TableCell>
                  <TableCell>
                    {/* <SortArrows > */}
                    <span className="font-wt-900">STATUS</span>
                    {/* </SortArrows> */}
                  </TableCell>
                  <TableCell>
                    <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="usageGB" onChange={handleChangeSorting}>
                      <span className="font-wt-900">USAGE (GB)/PLAN</span>
                    </SortArrows>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceLines?.length > 0 ? (
                  serviceLines.map((serviceLine: any, index: number) => (
                    <TableRow key={index}   >
                      <TableCell className={`selectedRow ${selectedServiceLine == serviceLine ? "highlight" : ""}`}>
                        <div className="d-flex">
                          <div className="starlinkMap-serviceLine" onClick={() => { setSelectedServiceLine(serviceLine) }} > {serviceLine?.nickname}</div>
                        </div>
                      </TableCell>
                      <TableCell className={`selectedRow ${selectedServiceLine == serviceLine ? "highlight" : ""}`}>
                        <div className="starlink-d-flex ">
                          <div>
                            {serviceLine?.opted_in ? (
                              <Tooltip title="Mobile Priority: Enabled" arrow >
                                <div>  <CircleIcon id={"greenDotSymbol"} />  </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Mobile Priority: Disabled" arrow >
                                <div> <CircleIcon id={"greyDotSymbol"} />   </div>
                              </Tooltip>
                            )}
                          </div>

                          <div className="str-mr-5">
                            {serviceLine?.userTerminals && serviceLine?.userTerminals !== '' ? JSON.parse(serviceLine?.userTerminals)?.some(t => t.online === "1") ? (
                              <Tooltip title={
                                <>
                                  {
                                    serviceLine.userTerminals !== '' && JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                      return <Grid classes={{ root: 'starlink--base--flex--align--center' }}>
                                        {userTerminal.kitSerialNumber ? <Fragment>
                                          <CircleIcon id={JSON.parse(userTerminal.online) ? "greenDotSymbol" : "redDotSymbol"} />
                                          {userTerminal.kitSerialNumber}
                                        </Fragment> : null}
                                      </Grid>
                                    })
                                  }
                                </>
                              } arrow >
                                <div className="d-flex align-center">
                                  <div> <CircleIcon className="str-mr-5" id={"greenDotSymbol"} /></div>
                                  <div>{serviceLine?.userTerminals !== '' && JSON.parse(serviceLine.userTerminals)?.length} </div>

                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title={
                                <>
                                  {
                                    serviceLine.userTerminals !== '' && JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any) => {
                                      return <Grid classes={{ root: 'starlink--base--flex--align--center' }}>
                                        {userTerminal.kitSerialNumber ? <Fragment>
                                          <CircleIcon id={JSON.parse(userTerminal.online) ? "greenDotSymbol" : "redDotSymbol"} />
                                          {userTerminal.kitSerialNumber}
                                        </Fragment> : null}
                                      </Grid>
                                    })
                                  }
                                </>
                              } arrow >
                                <div className="d-flex align-center">
                                  <div> <CircleIcon className="str-mr-5" id={"redDotSymbol"} /></div>
                                  <div>{serviceLine?.userTerminals !== '' && JSON.parse(serviceLine.userTerminals)?.length} </div>

                                </div>
                              </Tooltip>
                            ) : null}
                          </div>

                          <div>
                            {serviceLine?.userTerminals && serviceLine?.userTerminals !== '' ? (
                              <Tooltip
                                classes={{ tooltip: 'starlink--tooltip' }}
                                title={  serviceLine.userTerminals !== '' ?
                                  <div >
                                    {JSON.parse(serviceLine.userTerminals)?.map((userTerminal: any, index: number) => {
                                        const activeAlerts = JSON.parse(userTerminal.activeAlerts || '[]');
                                        return (
                                          activeAlerts.length > 0 && (
                                            <Table size="small" key={index}>
                                              <TableBody>
                                                {
                                                  activeAlerts.map((alert: any, alertIndex: number) => (
                                                    <TableRow key={alertIndex}>
                                                      <TableCell>{userTerminal.kitSerialNumber || 'N/A'}</TableCell>
                                                      <TableCell>{alertMap[alert] ? titleCase(alertMap[alert]) : titleCase(`alert_${alert}`)}</TableCell>
                                                    </TableRow>
                                                  ))
                                                }
                                              </TableBody>
                                            </Table>
                                          )
                                        );
                                      })
                                    }
                                  </div> : null
                                }
                                arrow
                              >
                                <div className="strMapIcon d-flex align-center">
                                  <div>  <WarningAmberIcon  className="alertIconColor str-font-15" /></div>
                                  <div className="str-alert">{
                                    serviceLine.userTerminals !== '' &&
                                    JSON.parse(serviceLine.userTerminals)
                                      ?.reduce((total: number, userTerminal: any) => {
                                        const alerts = JSON.parse(userTerminal?.activeAlerts || '[]');
                                        return total + alerts.length;
                                      }, 0)
                                  }</div>
                                </div>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                          </div>

                        </div>
                      </TableCell>
                      <TableCell className={`selectedRow ${selectedServiceLine == serviceLine ? "highlight" : ""}`}>
                        {serviceLine?.usageSplit != '' && JSON.parse(serviceLine?.usageGB) ? <Grid display={'flex'} alignItems={'center'}>
                          {JSON.parse(serviceLine?.usageSplit)?.length === 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                            <Grid>
                              <Table size="small">
                                <TableBody>
                                  <TableRow>
                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Priority GB'}</TableCell>
                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usagePriorityGB : '0'}  GB</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Opt In Priority GB'}</TableCell>
                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageOptInPriorityGB : '0'}  GB</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Standard GB'}</TableCell>
                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageStandardGB : '0'}  GB</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{'Non Billable GB'}</TableCell>
                                    <TableCell>{JSON.parse(serviceLine?.usageSplit) && JSON.parse(serviceLine?.usageSplit).length > 0 ? JSON.parse(serviceLine?.usageSplit)[0]?.usageNonBillableGB : '0'}  GB</TableCell>
                                  </TableRow>

                                </TableBody>
                              </Table>
                            </Grid>
                          }>
                            <Box>{`${serviceLine?.usageGB} ${serviceLine?.usageLimitGB ? `/ ${serviceLine?.usageLimitGB}`  : ""}`}</Box>
                          </Tooltip> : `${serviceLine?.usageGB} ${serviceLine?.usageLimitGB ? `/ ${serviceLine?.usageLimitGB}`  : ""}`}
                          {serviceLine?.usageSplit != '' && JSON.parse(serviceLine?.usageSplit)?.length > 1 ? <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                            <Grid>
                              <Table size="small">
                                <TableBody>
                                  {
                                    JSON.parse(serviceLine?.usageSplit)?.map((split: any) => <TableRow key={`tooltip-${split?.usageGB}`}>
                                      <TableCell classes={{ root: 'starlink--tooltip--value_title' }}>{STARLINK_DATAUSAGE_CATEGORIES[split.dataBucket] ? STARLINK_DATAUSAGE_CATEGORIES[split.dataBucket] : 'Unknown Bucket'}</TableCell>
                                      <TableCell>{split?.usageGB} GB</TableCell>
                                    </TableRow>)
                                  }
                                </TableBody>
                              </Table>
                            </Grid>
                          }>
                            <IconButton>
                              <CallSplitIcon fontSize="small" />
                            </IconButton>
                          </Tooltip> : null}
                        </Grid> : `0 GB ${serviceLine?.usageLimitGB ? `/ ${serviceLine?.usageLimitGB}`  : ""}`}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No service lines available.
                    </TableCell>
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <StarlinkMap starlinkLocations={starlinkLocations} serviceLines={serviceLines} selectedServiceLine={selectedServiceLine} setSelectedServiceLine={setSelectedServiceLine} />
      </div>

    </div>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  dpIds: state.starlinkCloud.dpIds,
  warnings: state.starlinkDashboard.warnings,
  serviceLines: state.starlinkDashboard.serviceLines,
  starlinkLastLocations: state.starlinkDashboard.starlinkLastLocations,
  totalServiceLines: state.starlinkDashboard.totalServiceLines,
  totalUsageGB: state.starlinkDashboard.totalUsageGB,
  totalOverageGB: state.starlinkDashboard.totalOverageGB,
  totalOverage: state.starlinkDashboard.totalOverage,
  totalUserTerminals: state.starlinkDashboard.totalUserTerminals,
  totalOnlineTerminals: state.starlinkDashboard.totalOnlineTerminals,
  totalAlertingTerminals: state.starlinkDashboard.totalAlertingTerminals,
  totalActiveAlerts: state.starlinkDashboard.totalActiveAlerts,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
  selectedServiceAccount: state?.authReducer?.selectedServiceAccount,

});

export default withRouter(
  connect(mapStateToProps, {
    getServiceLines,
    clearComponentData,
    getStarlinkLastLocation,
    downloadAsCSV
  })(StarlinkMaps)
);
